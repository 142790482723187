<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-container>
          <img alt="Password House" class="logo" src="@/assets/img/logo.svg" />
        </v-container>

        <v-container class="pb-8">
          <h1 class="text-h6 black--text">
            Sign in to securely store your passwords and share them with your
            family.
          </h1>
        </v-container>

        <transition name="fade">
          <div
            v-show="user === undefined || !networkOnLine"
            class="page-loader"
          >
            <v-img src="@/assets/img/preloader_puff.svg"></v-img>
          </div>
        </transition>

        <transition name="fade">
          <v-container class="mb-12">
            <p v-if="loginError">{{ loginError }}</p>
            <v-btn
              v-show="user !== undefined && !user && networkOnLine"
              data-test="login-btn"
              class="login-btn mb-4"
              color="primary"
              x-large
              block
              @click="login"
            >
              <v-icon class="mr-4" medium>fab fa-google</v-icon> Sign in with
              Google
            </v-btn>
            <v-btn
              v-show="user !== undefined && !user && networkOnLine"
              data-test="login-btn"
              class="login-btn mb-12"
              color="white"
              x-large
              block
              @click="login"
            >
              <v-icon class="mr-4" medium>fab fa-apple</v-icon> Sign in with
              Apple
            </v-btn>
          </v-container>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { desktop as isDekstop } from 'is_js'

export default {
  data: () => ({ loginError: null }),
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine'])
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/home'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    async login() {
      this.loginError = null
      const provider = new firebase.auth.GoogleAuthProvider()
      this.setUser(undefined)

      try {
        // Firebase signin with popup is faster than redirect
        // but we can't use it on mobile because it's not well supported
        // when app is running as standalone on ios & android
        // eslint-disable-next-line no-unused-expressions
        isDekstop()
          ? await firebase.auth().signInWithPopup(provider)
          : await firebase.auth().signInWithRedirect(provider)
      } catch (err) {
        this.loginError = err
        this.setUser(null)
      }
    }
  }
}
</script>

<style lang="sass" scoped></style>
